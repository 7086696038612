import React from 'react';

import { Menu } from 'antd';
import { Link, withRouter } from 'react-router-dom';

import { useLanguage } from 'app/language-context';
import useAsideMenu from 'app/pages/main/with-aside/aside/asideMenu';

import { getInfoLinks } from 'constants/header';

import heartImage from 'assets/images/svg/pic_heart.svg';
import ruFlagImage from 'assets/images/flags/ru.png';
import kzFlagImage from 'assets/images/flags/kz.jpg';
import enFlagImage from 'assets/images/flags/en.png';

import './MobileMenu.scss';

const MobileMenu = ({ isVisible, setInvisible, user, location }) => {
  const { lang, LANGUAGES, changeLang } = useLanguage();

  const asideMenu = useAsideMenu();

  return (
    <div className={`MobileMenu ${isVisible ? 'MobileMenu--visible' : ''}`}>
      <div className="container">
        <div className="row">
          <Menu mode="inline">
            {getInfoLinks(lang).map((link, index) => (
              <Menu.SubMenu
                title={
                  <Link to={link.routerParams} onClick={setInvisible}>
                    {link.title}
                  </Link>
                }
                key={index}
              >
                {link.children.map((nestedLink, i) => (
                  <Menu.Item key={`${index}-${i}`}>
                    <Link
                      to={nestedLink.routerParams}
                      className="Header__menu-item__link"
                      onClick={setInvisible}
                    >
                      {nestedLink.title}
                    </Link>
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            ))}
            {user
              ? asideMenu.slice(0, asideMenu.length - 1).map((item, index) => {
                return user && !user.pay_zakyat && item.zakyatOnly ? null : (
                  <Menu.Item className="MobileMenu__item" key={`last-${index}`}>
                    <Link to={item.path} onClick={setInvisible}>
                      {item.title}
                    </Link>
                  </Menu.Item>
                );
              })
              : null}
            <Menu.Item className="MobileMenu__item">
              <Link to="/help" onClick={setInvisible}>
                <img src={heartImage} alt="heart" className="Header__btn__heart" />{' '}
                {lang.HEADER.HELP}
              </Link>
            </Menu.Item>
            <Menu.Item
              className="MobileMenu__item"
              onClick={() => {
                changeLang(LANGUAGES.KAZ);
              }}
            >
              <div className="d-flex align-items-center">
                <div className="Header__lang__flag">
                  <img src={kzFlagImage} alt="flag" />
                </div>
                {lang.HEADER.LANGUAGES.KAZ}
              </div>
            </Menu.Item>
            <Menu.Item
              className="MobileMenu__item"
              onClick={() => {
                changeLang(LANGUAGES.RU);
              }}
            >
              <div className="d-flex align-items-center">
                <div className="Header__lang__flag">
                  <img src={ruFlagImage} alt="flag" />
                </div>
                {lang.HEADER.LANGUAGES.RU}
              </div>
            </Menu.Item>
            <Menu.Item
              className="MobileMenu__item"
              onClick={() => {
                changeLang(LANGUAGES.EN);
              }}
            >
              <div className="d-flex align-items-center">
                <div className="Header__lang__flag">
                  <img src={enFlagImage} alt="flag" />
                </div>
                {lang.HEADER.LANGUAGES.EN}
              </div>
            </Menu.Item>
          </Menu>
          {user ? (
            <div className="d-flex justify-content-around align-items-center flex-wrap MobileMenu__bottom">
              <Link to="/profile/beneficiaries">
                <button className="button" onClick={setInvisible}>
                  {lang.HEADER.CABINET}
                </button>
              </Link>
              <Link to="/auth/logout">
                <button className="button" onClick={setInvisible}>
                  {lang.HEADER.LOGOUT}
                </button>
              </Link>
            </div>
          ) : (
            <div className="d-flex justify-content-around align-items-center flex-wrap MobileMenu__bottom">
              <a href="https://my.niet.kz/auth">
                <button className="button" onClick={setInvisible}>
                  {lang.HEADER.LOGIN}
                </button>
              </a>
              <Link to="/auth/register">
                <button className="button" onClick={setInvisible}>
                  {lang.HEADER.REGISTER}
                </button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(MobileMenu);
